import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'
const topUrl = process.env.NEXT_PUBLIC_FRONT_BASE_URL || '/'

const LinkButton: FC<Props> = ({ children, href = topUrl }) => {
  return (
    <Link href={href} passHref>
      <Button>{children}</Button>
    </Link>
  )
}
export default LinkButton

type Props = {
  children: string
  href?: string | undefined
}

const Button = styled.button`
  padding: 10px 0;
  width: 200px;
  color: #fff;
  border: none;
  border-radius: 30px;
  background: linear-gradient(
    281.38deg,
    #000000 -26.3%,
    #e7553e -26.28%,
    #f0a338 110.05%
  );
  border: 1px solid #ef943a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &:hover {
    background: #fff;
    color: #ef943a;
  }
`
