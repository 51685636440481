export const SUPPORT_LOCALES: Locale[] = [
  { code: 'ja', label: '日本語' },
  { code: 'en', label: 'English' },
  // { code: 'ko', label: '한국어' },
  // { code: 'zh', label: '中文' },
  // { code: 'th', label: 'ภาษาไทย' },
]

export function supportsLocaleCode(
  localeCode: string
): localeCode is LocaleCode {
  const supportLocaleCodes = SUPPORT_LOCALES.map(
    (locale) => locale.code as string
  )
  return supportLocaleCodes.includes(localeCode)
}

export const showsLocaleSetting = true

type Locale = { code: LocaleCode; label: string }
export type LocaleCode = 'en' | 'ja' | 'ko' | 'zh' | 'th'
