import axios from 'axios'
import * as Sentry from '@sentry/nextjs'

export type Result<T> = Success<T> | Failure

export class Success<T> {
  readonly isSuccess = true as const

  constructor(readonly data: T) {}
}

// TODO: それぞれのケースの相応しいエラー文言考える
export class Failure {
  readonly isSuccess = false as const

  constructor(
    readonly failureType: FailureType,
    readonly redirectUrl?: string,
    readonly message: string = DEFAULT_MESSAGES[failureType]
  ) {}

  static of(error: unknown) {
    if (axios.isAxiosError<FailureData>(error)) {
      const res = error.response
      if (!res) {
        return new Failure('network_error')
      } else if (res.status >= 500) {
        Sentry.captureException(error)
        return new Failure('server_error', res.data.redirectUrl)
      } else if (res.status === 401) {
        return new Failure(
          'unauthorized',
          res.data.redirectUrl,
          res.data.message
        )
      } else {
        return new Failure(
          'client_error',
          res.data.redirectUrl,
          res.data.message
        )
      }
    } else {
      Sentry.captureException(error)
      return new Failure('server_error')
    }
  }

  isUnauthorized() {
    return this.failureType == 'unauthorized'
  }

  async handleError() {
    if (this.redirectUrl) location.assign(this.redirectUrl)
  }
}

export type FailureData = {
  failureType?: FailureType
  redirectUrl?: string
  message?: string
}
type FailureType =
  | 'client_error'
  | 'network_error'
  | 'server_error'
  | 'unauthorized'

const DEFAULT_MESSAGES: Record<FailureType, string> = {
  client_error: 'エラー',
  unauthorized: '認証エラー',
  network_error: 'ネットワークエラー',
  server_error: 'システムエラー',
}
