import { useCallback, useMemo, useState } from 'react'
import { CookieSerializeOptions, parse, serialize } from 'cookie'

export const useCookie = (): CookieStates => {
  const [cookies, setCookies] =
    useState<Record<string, string>>(parseDocumentCookie)
  const setCookie = useCallback(
    (name: string, value: string, option?: CookieSerializeOptions) => {
      setDocumentCookie(name, value, option)
      setCookies(parseDocumentCookie())
    },
    []
  )
  const removeCookie = useCallback(
    (name: string, option?: CookieSerializeOptions) => {
      setCookie(name, '', { ...option, maxAge: -1 })
    },
    [setCookie]
  )
  return { cookies, setCookie, removeCookie }
}

export type CookieStates = {
  cookies: Record<string, string>
  setCookie: (
    name: string,
    value: string,
    option?: CookieSerializeOptions
  ) => void
  removeCookie: (name: string, option?: CookieSerializeOptions) => void
}

export const useCookieValue = <V extends string>(
  name: string,
  defaultValue: V,
  option?: CookieSerializeOptions
): CookieValueStates<V> => {
  const resolvedOptions = useMemo(() => withDefaultOptions(option), [option])
  const [value, setValue] = useState(() => {
    const cookies = parseDocumentCookie()
    return (cookies[name] as V | undefined) ?? defaultValue
  })
  const setCookieValue = useCallback(
    (value: V) => {
      setValue(value)
      setDocumentCookie(name, value, resolvedOptions)
    },
    [name, resolvedOptions]
  )
  const removeCookieValue = useCallback(() => {
    setValue(defaultValue)
    setDocumentCookie(name, '', { ...resolvedOptions, maxAge: -1 })
  }, [defaultValue, name, resolvedOptions])
  return [value, setCookieValue, removeCookieValue]
}

export type CookieValueStates<V> = [
  value: V,
  setCookie: (value: V) => void,
  remove: () => void
]

function withDefaultOptions(
  options?: CookieSerializeOptions
): CookieSerializeOptions {
  return {
    path: '/',
    secure: true,
    sameSite: 'lax',
    domain: process.env.NEXT_PUBLIC_FRONT_BASE_DOMAIN,
    ...options,
  }
}

function setDocumentCookie(
  name: string,
  value: string,
  option?: CookieSerializeOptions
) {
  document.cookie = serialize(name, value, option)
}

function parseDocumentCookie(): Record<string, string> {
  return parse(document.cookie)
}
