import { Result } from 'apis/result'
import { requestApi } from 'apis/base'
import { LocaleCode } from 'lib/locale'

export const localeApi = {
  update(input: UpdateInput): Promise<Result<void>> {
    return requestApi('PUT', 'locale', input)
  },
}

type UpdateInput = {
  code: LocaleCode
}
