import { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import useOnetimeLoad from 'hooks/useOnetimeLoad'
import { hasCsrfToken } from 'apis/base'
import { sessionsApi } from 'apis/sessionsApi'
import { LocaleContextProvider } from 'contexts/LocaleContext'
import { useRouter } from 'next/router'
import { LocaleCode, supportsLocaleCode } from 'lib/locale'

const AnonymousLayout: FC<PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState(false)
  useOnetimeLoad(async () => {
    if (!hasCsrfToken()) await sessionsApi.csrf()
    setReady(true)
  })
  const router = useRouter()
  const [locale, setLocale] = useState<LocaleCode | null>()
  useEffect(() => {
    if (router.isReady && locale === undefined) {
      const queryLocale = router.query['locale'] as string
      setLocale(supportsLocaleCode(queryLocale) ? queryLocale : null)
    }
  }, [locale, router.isReady, router.query])
  return ready && locale !== undefined ? (
    <LocaleContextProvider defaultLocaleCode={locale}>
      {children}
    </LocaleContextProvider>
  ) : (
    <></>
  )
}

export const getLayout = (page: ReactElement) => (
  <AnonymousLayout>{page}</AnonymousLayout>
)
