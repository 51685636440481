import { useEffect, useState } from 'react'

const useOnetimeLoad = (
  callback: CallbackWithNoArgument,
  conditions: ConditionList = []
) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!loaded && conditions.every((condition) => condition)) {
      callback().finally(() => setLoaded(true))
    }
  }, [loaded, ...conditions])
}
export default useOnetimeLoad

type CallbackWithNoArgument = () => Promise<void>
type ConditionList = boolean[]
