import { requestApi } from 'apis/base'
import { Result } from 'apis/result'

export const sessionsApi = {
  login: (input: LoginInput): Promise<Result<Data>> => {
    return requestApi('POST', 'login', input)
  },
  logout: (): Promise<Result<Data>> => {
    return requestApi('DELETE', 'logout')
  },
  me: (): Promise<Result<MeOutput>> => {
    return requestApi('GET', 'me')
  },
  csrf: (): Promise<Result<void>> => {
    return requestApi('GET', 'csrf')
  },
}

export type LoginInput = {
  loginId: string
  password: string
}

export type Data = {
  redirectUrl: string
}

export type MeOutput = {
  me: {
    id: number
    name: string
    email: string
  }
}
